<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getSkuReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 id="SKUReportTitle" class="mb-0 font-weight-normal">
              {{ $t('report.txtsku') }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div class="col-md-2 col-sm-3 text-right text-success">
              <CButton
                id="SKUReportExportButton"
                v-if="isExport"
                v-on:click="exportSkuReport()"
                block
                color="info"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <DataTable
                id="SKUReportDatatable"
                style="white-space: nowrap;"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
              >
                <template #onhandQty="{ item }">
                    <td v-if="floatValue(item.onhandQty) < 0.00" class="text-right text-danger">{{ item.onhandQty }}</td>
                    <td v-else class="text-right text-dark">{{ item.onhandQty }}</td>
                </template>
                <template #profit="{ item }">
                    <td v-if="floatValue(item.profit) < 0.00" class="text-right text-danger">{{ item.profit }}</td>
                    <td v-else class="text-right text-dark">{{ item.profit }}</td>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getSkuReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    Pagination,
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center text-dark font-weight-normal',
          _style: 'width:5%',
        },
        { key: 'productPLU', label: this.$i18n.t('pluCode'), _classes: 'text-dark font-weight-normal', },
        {
          key: 'name',
          label: this.$i18n.t('productName'),
          _style: 'width:30%',
          _classes: 'text-dark font-weight-normal',
        },
        { key: 'category', label: this.$i18n.t('category'),_classes: 'text-dark font-weight-normal', },
        {
          key: 'quantity',
          label: this.$i18n.t('countSales'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('sales'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        { key: 'cost', label: this.$i18n.t('cost'), _classes: 'text-right text-dark font-weight-normal', },
        {
          key: 'profit',
          label: this.$i18n.t('profit'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []
      let catagory = ''
      let start = this.meta_data.current_page * 50 - 50
      let onhandQty = ''
      for (let i = 0; i < data.length; i++) {
        if (data[i].category == undefined) {
          catagory = '-'
        } else {
          catagory = data[i].category.name
        }
        let plu = '-'
        let name = '-'
        let cost = '-'
        let profit = '-'

        if (data[i].productPLU === undefined) {
          plu = '-'
        } else {
          if (data[i].productPLU.PLUCode === undefined) {
            plu = '-'
          } else {
            plu = data[i].productPLU.PLUCode
          }
          if (data[i].productPLU.name === undefined) {
            name = '-'
          } else {
            name = data[i].productPLU.name
          }
        }
        if (data[i].totalCost !== undefined) {
          cost = util.convertCurrency(data[i].totalCost)
        }

        if (data[i].profit !== undefined) {
          profit = util.convertCurrency(data[i].profit)
        }
        
        if (data[i].skuType) {
          if (data[i].skuType === 'BOM' || data[i].skuType === 'SV') {
            onhandQty = this.$t('nonStock')
          } else {
            onhandQty = data[i].onhandQty || 0
          }
        } else {
          onhandQty = data[i].onhandQty || 0
        }

        detail.push({
          id: start + i + 1,
          productPLU: plu,
          name: name,
          category: catagory,
          quantity: data[i].quantity,
          onhandQty: onhandQty,
          cost: cost,
          profit: profit,
          netAmount: util.convertCurrency(data[i].netAmount),
        })
      }

      return detail
    },
  },
  created() {
    this.getSkuReport()
  },
  methods: {
    ...util,
    getSkuReport(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = { shopObjectId: shopObjectId }

      axios({
        url: '/receipt/v1.0/productsellbyskureport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportSkuReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/receipt/v1.0/productsellbyskureport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานสรุปจำนวนการขายสินค้า.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>
